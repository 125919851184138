import React, { useState, useEffect } from 'react';
import '../styles/shopnow.css';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Post from '../components/Post';
import Loader from '../components/Loader';
import StoriesServices from '../services/StoriesServices';
import AuthServices from '../services/AuthServices';
import profile from '../assets/images/person.jpeg';

function FeedScreen() {
    const navigate = useNavigate();
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);

    const fetchStories = async (pgno = 0) => {
        setLoading(true);
        try {
            const [userResponse, storiesResponse] = await Promise.all([
                AuthServices.getUserDetails(navigate),
                StoriesServices.getStories(pgno)
            ]);

            setUser(userResponse?.data || null);
            setStories(storiesResponse?.data || []);
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Something went wrong while fetching stories.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStories();
    }, []);

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <div className='screen_container justify-content-between' style={{ height: loading ? "84.2vh" : "auto" }}>
                <div className='d-flex justify-content-between align-items-center'>
                    <Header />
                    {user && (
                        <img
                            src={user.imagePath || profile}
                            alt='Profile'
                            className='profile-img'
                            onClick={() => navigate('/profile')}
                        />
                    )}
                </div>
                <ToastContainer toastClassName='toaster' />
                {loading ? (
                    <Loader />
                ) : (
                    <div className='d-flex flex-column align-items-center mt-3'>
                        <p className='text-white text-center w-75'>
                            Preserve cherished memories and share the stories of your ancestors or loved ones with others. Ensure their legacy lives on through meaningful tributes and remembrances.
                        </p>
                        {stories.length > 0 ? (
                            <div className='pt-4 w-100'>
                                {stories.map((story, index) => (
                                    <Post
                                        key={index}
                                        post={story?.filePath}
                                        likes={story?.likesCollection}
                                        commentcollection={story?.comments}
                                        medallion={story?.medallion}
                                        user={user}
                                        storyId={story?.id}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div className='w-100 d-flex align-items-center justify-content-center flex-column border-white border-1' style={{ height: "52vh" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="white" className="bi bi-emoji-frown" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.5 3.5 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.5 4.5 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                                </svg>
                                <h2 className='text-white text-center my-2'>Stories not Available</h2>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default FeedScreen;
