import axios from 'axios';
import { BASE_URL } from './BASE_URL';
import AuthServices from './AuthServices';

const API_URL = `${BASE_URL}/api/Payment`;
const token = AuthServices.getToken();

const initiatePayment = async (paymentData) => {
    try {
        const response = await axios.post(
            `${API_URL}/Create`,
            paymentData,
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return { status: true, data: response.data };
    } catch (error) {
        console.error('Error creating payment:', error.response?.data?.statusMessage || "An unknown error occurred.");
        return { status: false, message: error.response?.data?.statusMessage || "Failed to initiate payment." };
    }
};

const PaymentServices = {
    initiatePayment
};

export default PaymentServices;
