import React from 'react';
import '../styles/request-notification.css';
import profile from '../assets/images/person.jpeg';

function RequestNotification({ message, requester, requestTime, onAllow, onDecline }) {
    return (
        <div className='request-notification-container p-3 mb-3'>
            <div className='d-flex align-items-center'>
                <img src={requester.imagePath || profile} className='requester-profile-image'/>
                <div className='ms-2'>
                    <p className='request-info'>{message}</p>
                    <p className='request-time mt-1 mb-0'>{requestTime}</p>
                </div>
            </div>
            <div className='request-actions d-flex justify-content-end mt-2'>
                <button className='btn btn-success me-2' onClick={onAllow}>Allow</button>
                <button className='btn btn-danger' onClick={onDecline}>Decline</button>
            </div>
        </div>
    );
}

export default RequestNotification;
