import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthServices from '../services/AuthServices';

const PublicRoute = ({ element: Component, ...rest }) => {
    const user = AuthServices.getToken(); 

    return user ? <Navigate to="/home" replace /> : <Component {...rest} />;
};

export default PublicRoute;
