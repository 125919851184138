import React from "react";
import styled from "styled-components";

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Tab = styled.div`
  width: 100%;
  border-bottom: ${(props) =>
    props.isActive ? "2px solid white" : "1px solid #111"};
  svg {
    fill: ${(props) => (props.isActive ? "#fff" : "#919191")};
    transform: scale(2);
  }
  button {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    padding: 35px 27px 16px;
  }
`;

const TabItemsLabel = styled.span`
  font-size: 12px;
  white-space: nowrap;
  margin-top: 6px;
  color: white;
  font-weight: 600;
`;

function Tabs({ activeTab, setActiveTab }) {
  return (
    <TabsWrapper>
      <Tab isActive={activeTab === "About Me"}>
        <button onClick={() => setActiveTab("About Me")}>
          <TabItemsLabel>About Me</TabItemsLabel>
        </button>
      </Tab>
      <Tab isActive={activeTab === "Media"}>
        <button onClick={() => setActiveTab("Media")}>
          <TabItemsLabel>Media</TabItemsLabel>
        </button>
      </Tab>
      <Tab isActive={activeTab === "Tributes"}>
        <button onClick={() => setActiveTab("Tributes")}>
          <TabItemsLabel>Tributes</TabItemsLabel>
        </button>
      </Tab>
    </TabsWrapper>
  );
}

export default Tabs;
