import { configureStore } from '@reduxjs/toolkit';
import highlightsReducer from './HighlightSlice';

const store = configureStore({
  reducer: {
    highlights: highlightsReducer,
  },
});

export default store;
