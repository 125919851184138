import axios from 'axios';
import { BASE_URL } from './BASE_URL';
import AuthServices from './AuthServices';
import { toast } from 'react-toastify';

const API_URL = `${BASE_URL}/api/MedallionRequest`;
const token = AuthServices.getToken();

const getRequests = async (id) => {
    try {
        const response = await axios.get(
            `${API_URL}/GetMedallionRequestByQwnerId?UserId=${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error getting medallion requests:', error);
        return false;
    }
};

const requestViewMedallion = async (medallionData) => {
    try {
        const response = await axios.post(`${API_URL}/RequestViewMedallion`, medallionData, {
            headers: {
                Authorization: `Bearer ${token.data.access_token}`,
                'Content-Type': 'application/json-patch+json',
            },
        });

        if (response.data.statusCode === 200) {
            return response.data;
        }
    } catch (error) {
        if (error.response && error.response.data.statusCode === 400) {
            const errorData = error.response.data;
            if (errorData.statusMessage === "You have already requested") {
                toast.error("You have already requested to view this medallion.");
            } else {
                toast.error(errorData.statusMessage || "An error occurred.");
            }
        } else {
            toast.error("An unexpected error occurred.");
        }
        return { statusCode: 400 };
    }
};

const ApproveRequest = async (id) => {
    try {
        const response = await axios.post(`${API_URL}/ApproveRequest`,
            {
                requestId: id
            }, 
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`,
                    'Content-Type': 'application/json-patch+json',
                },
            });

        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 403) {
            return { statusCode: 403, message: "Forbidden: You do not have permission to approve this request." };
        } else {
            console.error('Error approving request:', error);
            throw new Error(error.response ? error.response.data.message : "An error occurred");
        }
    }
};

const DeclineRequest = async (id) => {
    try {
        const response = await axios.post(`${API_URL}/DeclineRequest`,
            {
                requestId: id
            }, 
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`,
                    'Content-Type': 'application/json-patch+json',
                },
            });

        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 403) {
            return { statusCode: 403, message: "Forbidden: You do not have permission to decline this request." };
        } else {
            console.error('Error declining request:', error);
            throw new Error(error.response ? error.response.data.message : "An error occurred");
        }
    }
};

const RequestServices = {
    getRequests,
    requestViewMedallion,
    ApproveRequest,
    DeclineRequest
};

export default RequestServices;
