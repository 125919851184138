import React, { useState, useEffect } from 'react';
import '../styles/owner-profile.css';
import Header from '../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import profile from '../assets/images/person.jpeg';
import QRCodeServices from '../services/QRCodeServices';
import MedallionServices from '../services/MedallionServices';
import { BASE_URL } from '../services/BASE_URL';
import { formatDate } from '../functions/CommonFunctions';

function QRRedirectScreen() {
    const navigate = useNavigate();
    const { uuid } = useParams();

    const [medallion, setMedallion] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const qrResponse = await QRCodeServices.getQrCodeByGuId(uuid);
                if (qrResponse) {
                    if (qrResponse.data) {
                        const medallionResponse = await MedallionServices.getByID(Number(qrResponse.data));
                        setMedallion(medallionResponse.data);
                    }
                } else {
                    toast.error('QR code not found.');

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error('Failed to fetch medallion details.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [uuid, navigate]);

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <div className='screen_container d-flex flex-column' style={{ height: loading ? "84.2vh" : "auto", minHeight: '84.2vh' }}>
                <Header /> {/* Header will stay at the top */}

                <div className='content d-flex flex-column justify-content-center align-items-center flex-grow-1'>
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <ToastContainer toastClassName='toaster' />
                            {
                                medallion ? <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <img
                                        src={medallion?.imagePath ? `${BASE_URL}${medallion.imagePath}` : profile}
                                        className='rounded-circle profile-image mt-2 mb-3'
                                        alt='Profile'
                                    />
                                    <p className='text-white fw-4 fs-4 text-center'>{medallion?.displayName || 'No Name Available'}</p>
                                    <p style={{ color: '#b6b1b1', fontSize: 14 }}>
                                        {formatDate(medallion?.dateOfBirth)} - {formatDate(medallion?.dateOfDeath)}
                                    </p>
                                    <div className='mx-auto mt-3'>
                                        <div className='profile-btn h-auto py-2 px-3' onClick={() => navigate(`/d-profile/${medallion?.id}`)}>
                                            <p className='text-white mb-0 text-center'>Visit Profile</p>
                                        </div>
                                    </div>
                                </div> : <p className='text-white fw-4 fs-4 text-center'>There is no profile against this QR Code</p>
                            }
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default QRRedirectScreen;
