import React from 'react';
import '../styles/shopnow.css';
import Header from '../components/Header';
import { packages } from '../constants/Packages';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function ShopNow() {
    const navigate = useNavigate();

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <div className='screen_container justify-content-between h-auto'>
                <Header />
                <ToastContainer toastClassName='toaster' />
                <div className='d-flex flex-column align-items-center mt-3'>
                    <h4 className='text-white fw-2 text-center w-50'>
                        Create a Memory for a Loved One
                    </h4>
                    <p className='text-white text-center w-50'>
                        Unlock the owner app and a unique barcode will be sent to you to place onto the headstone.
                    </p>
                    <p className='text-white text-center w-75 mt-2 light'>
                        One-time lifetime payment, no subscription.
                    </p>
                    {packages.map((pack) => (
                        <div className='w-100 mx-auto' key={pack.title}>
                            <div
                                className='gradientBox h-auto pe-3 py-2'
                                onClick={() => {
                                    const title = pack.title.split(' ')[0].toLowerCase();
                                    navigate(`/shop-now/${title}`);
                                }}
                            >
                                <div className='section1'>
                                    <p className='fs-4 text-uppercase text-white mb-0'>{pack.title}</p>
                                    <p className='text-white light mb-0'>{pack.description}</p>
                                </div>
                                <div className='section2'>
                                    <p className='text-white mb-0 fw-3'>{pack.price}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ShopNow;
