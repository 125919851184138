import React from 'react'
import logo from '../assets/images/logo.png'
import '../styles/header.css'
import logout from '../assets/icons/logout.png'
import { Link } from 'react-router-dom'

function Header({ button = false }) {
  return (
    <div>
      <div className='d-flex align-items-center'>
        <img src={logo} alt="Heaven's Code" className='logo' />
        <p className='text-white fw-3 ms-2 mb-0'>Heaven's Code</p>
      </div>
    </div>
  )
}

export default Header