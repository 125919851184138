import React from "react";
import styled from "styled-components";
import '../../styles/variables.css'
import { calculateAge, formatDate } from "../../functions/CommonFunctions";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-block: 30px
`;

const DeceasedName = styled.h3`
    color: #FFFFFF
`;

const Age = styled.span`
    color: var(--grey);
    font-size: 12px
`;

const Date = styled.p`
    color: var(--grey);
    font-size: 14px
`;

const Description = styled.p`
    margin-top: 10px;
    margin-bottom: 20px;
    color: var(--form-background-color);
    font-size: 14px;
    text-align: justify   
`;


function AboutMe({ medallion }) {

    const age = calculateAge(medallion.dateOfBirth, medallion.dateOfDeath);

    const dob = formatDate(medallion.dateOfBirth);
    const dod = formatDate(medallion.dateOfDeath);

    const descriptionText = `${medallion.about}`.replace(/\n/g, '<br />');


    return (

        <Wrapper>
            <DeceasedName>{medallion.displayName} <Age>({age} Years)</Age></DeceasedName>
            <Date>({dob} - {dod})</Date>
            <Description dangerouslySetInnerHTML={{ __html: descriptionText }} />
        </Wrapper>
    )
}

export default AboutMe