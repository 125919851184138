import React, { useState } from 'react'
import '../styles/shopnow.css'
import Header from '../components/Header';
import { Button, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import PaymentServices from '../services/PaymentServices';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';

function ShopNowSingle() {

    const navigate = useNavigate();

    const [name, setName] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const [loading, setLaoding] = useState(false);

    const pay = async () => {
        setLaoding(true);
        if (name.trim() === "" || email.trim() === "") {
            toast.error('Plz fill the required fields');
            setLaoding(false);
            return;
        }
        const data = {
            userName: name.trim(),
            userEmail: email.trim(),
            packageType: 1,
            numberOfQRCodes: 1,
            amount: 70,
            isPaid: true,
        }
        const response = await PaymentServices.initiatePayment(data);

        console.log("Response: ", response);
        if (response.status) {
            navigate("/profile");
            setLaoding(false);
        }
        else {
            setLaoding(false);
            toast.error(response.message);
        }
    }

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <div className='screen_container h-auto'>
                <ToastContainer toastClassName='toaster' />

                {
                    loading ? <Loader /> : (
                        <div>

                            <Header />
                            <div className='gradientBox h-auto pe-3 py-2 mt-5 mb-0 cursor-none single-package'>
                                <div className='section1'>
                                    <p className='fs-4 text-uppercase text-white mb-0'>Single</p>
                                    <p className='text-white light mb-0'>Create a memory for loved one</p>
                                </div>
                                <div className='section2'>
                                    <p className='text-white mb-0 fw-3'>$70</p>
                                </div>
                            </div>
                            <div className='d-flex flex-column align-items-center pt-3 single-package-bg'>
                                <p className='text-white text-center w-75'>You are buying single owner package and and unique barcode. The barcode will be sent to you to place on headstone.</p>
                                <Form className='single-form d-flex flex-column justify-content-center align-items-center'>
                                    <Form.Group className="mb-3 w-100" controlId="name">
                                        <Form.Control type="text" placeholder="Name" className='shopnow-input' value={name} onChange={handleNameChange} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 w-100" controlId="email">
                                        <Form.Control type="email" placeholder="Email Address" className='shopnow-input' value={email} onChange={handleEmailChange} />
                                    </Form.Group>
                                    <Button className='paybtn my-2' id="paybtn" type="button" onClick={() => {
                                        pay();
                                    }}>
                                        Pay
                                    </Button>
                                </Form>
                                <p className='text-white text-center w-75 mt-2 light'>On off lifetime payment, no subscription.</p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ShopNowSingle
