import { createSlice } from '@reduxjs/toolkit';
import highlight1 from "../assets/images/highlight1.png";
import highlight2 from "../assets/images/highlight2.png";
import highlight3 from "../assets/images/highlight3.png";
import highlight4 from "../assets/images/highlight4.png";
import highlight5 from "../assets/images/highlight5.png";

const initialState = [
  { src: highlight1, type: 'image' },
  { src: highlight2, type: 'image' },
  { src: highlight3, type: 'image' },
  { src: highlight4, type: 'image' },
  { src: highlight5, type: 'image' },
];

const highlightsSlice = createSlice({
  name: 'highlights',
  initialState,
  reducers: {
    addHighlight: (state, action) => {
        const { src, type } = action.payload; 
        state.push({ src, type });
      },
  },
});

export const { addHighlight } = highlightsSlice.actions;
export default highlightsSlice.reducer;