import axios from 'axios';
import { BASE_URL } from './BASE_URL';
import AuthServices from './AuthServices';
import { toast } from 'react-toastify';

const API_URL = `${BASE_URL}/api/Notifications`;
const token = AuthServices.getToken();

const getNotifications = async (size) => {
    try {
        const response = await axios.post(
            `${API_URL}/PaginationList`,
            {
                pageNumber: 0,
                pageSize: size
            },
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error getting notifications:', error);
        toast.error('Failed to retrieve notifications.');
        return false;
    }
};

const updateReadAt = async (id) => {
    try {
        const response = await axios.get(
            `${API_URL}/ReadNotfication?Id=${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error updating notification read status:', error);
        toast.error('Failed to update notification status.');
        return false;
    }
};

const NotificationServices = {
    getNotifications,
    updateReadAt
};

export default NotificationServices;
