import React, { useState, useEffect } from 'react';
import '../styles/owner-profile.css';
import Header from '../components/Header';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import Profile from '../components/profile/Profile';
import StoriesServices from '../services/StoriesServices';
import MedallionServices from '../services/MedallionServices';
import AuthServices from '../services/AuthServices';
import TributesServices from '../services/TributesServices';
import RequestServices from '../services/RequestServices';

function DeceasedProfileScreen() {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const [stories, setStories] = useState([]);
    const [tributes, setTributes] = useState([]);
    const [medallion, setMedallion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isUser, setIsUser] = useState(false);
    const [user, setUser] = useState(null);
    const [isPrivate, setIsPrivate] = useState(false);

    useEffect(() => {
        const fetchMedallion = async () => {
            try {
                const token = AuthServices.getToken();
                let medallionData;

                if (location.state?.medallion) {
                    medallionData = location.state.medallion;
                    const userResponse = await AuthServices.getUserDetails(navigate);
                    setUser(userResponse.data);
                    const isCurrentUser = await AuthServices.isCurrentUser(medallionData.createdBy, navigate);
                    setIsUser(isCurrentUser);
                } else {
                    let response;
                    if (token) {
                        const userResponse = await AuthServices.getUserDetails(navigate);
                        setUser(userResponse.data);
                        response = await MedallionServices.getMedallionByID(id);
                    } else {
                        response = await MedallionServices.getByID(id);
                    }

                    if (response.data) {
                        medallionData = response.data;
                        if (medallionData.isPrivate) {
                            if (!token) {
                                setIsPrivate(true);
                                return;
                            } else {
                                response = await MedallionServices.getMedallionByID(id);
                                if (response.statusCode === 403) {
                                    setIsPrivate(true);
                                    return;
                                }
                            }
                        }
                        if (token) {
                            const isCurrentUser = await AuthServices.isCurrentUser(medallionData.createdBy, navigate);
                            setIsUser(isCurrentUser);
                        }
                    } else {
                        throw new Error('Medallion not found');
                    }
                }

                setMedallion(medallionData);
            } catch (error) {
                toast.error('An error occurred while fetching the data.');
                navigate(-1);
            } finally {
                setLoading(false);
            }
        };

        fetchMedallion();
    }, [id, location.state, navigate]);

    useEffect(() => {
        if (!medallion) return;

        const fetchDetails = async () => {
            try {
                const [storiesResponse, tributesResponse] = await Promise.all([
                    StoriesServices.getStoriesByID(medallion.id),
                    TributesServices.getTributesByID(medallion.id)
                ]);

                setStories(storiesResponse || []);
                setTributes(tributesResponse || []);
            } catch (error) {
                toast.error('Failed to fetch stories or tributes.');
            }
        };

        fetchDetails();
    }, [medallion]);

    const handleRequestAccess = async () => {
        if (!user) {
            toast.error('You need to be logged in to send a request.');
            return;
        }

        setLoading(true);
        try {
            const res = await RequestServices.requestViewMedallion({
                medallionId: id,
                requestingUserId: user.id
            });
            if (res.statusCode === 200) {
                toast.success('Request sent successfully.');
            } else {
                toast.error('Failed to send request.');
            }
        } catch (error) {
            toast.error('An error occurred while sending the request.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <div className={`screen_container d-flex align-items-center`} style={{ height: loading ? "84.2vh" : "auto", minHeight: "84.2vh" }}>
                <ToastContainer toastClassName='toaster' />
                {loading ? (
                    <Loader />
                ) : (
                    <div style={{ height: isPrivate ? "60vh" : 'auto' }}>
                        <div className='d-flex justify-content-between align-items-center pb-3'>
                            <Header />
                        </div>
                        {isPrivate ? (
                            <div className='h-100 d-flex flex-column align-items-center justify-content-center'>
                                <h4 className='text-white fw-2 text-center'>Hi, {user?.displayName}</h4>
                                <p className='text-white text-center'>
                                    This profile is private and cannot be viewed without permission. If you'd like to access this profile, please send a request to the owner for viewing rights.
                                </p>
                                <div className='profile-btn h-auto py-2 w-50' onClick={handleRequestAccess}>
                                    <p className='text-white mb-0 text-center'>Send Request</p>
                                </div>
                            </div>
                        ) : (
                            <Profile medallion={medallion} stories={stories} isUser={isUser} tributes={tributes} user={user} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default DeceasedProfileScreen;
