import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from './BASE_URL';
import { useNavigate } from 'react-router-dom';

const API_URL = `${BASE_URL}/api/Accounts/`;

const register = async (displayName, email, password, imagePath) => {
  const data = imagePath ? { displayName, email, password, imagePath } : { displayName, email, password };

  try {
    return await axios.post(`${API_URL}SignUp`, data);
  } catch (error) {
    console.error("Error during registration:", error);
    toast.error("Registration failed.");
  }
};

const socialSignin = async (userName, email, picture) => {
  const password = "qwertyuiopkljhgfdcvbnm987654356789";
  try {
    const response = await axios.post(`${API_URL}SocialSignIn`, {
      userName,
      email,
      password,
      picture,
    });
    console.log("Response", response);
    if (response.status === 200) {
      localStorage.setItem('user', JSON.stringify(response));
      window.location.reload();
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error during social sign-in:", error);
    toast.error("Social sign-in failed.");
    return false;
  }
};

const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}Login`, { email, password });
    if (response.data.access_token) {
      localStorage.setItem('user', JSON.stringify(response));
      window.location.reload();
      return true;
    }
  } catch (error) {
    console.error("Error during login:", error);
    toast.error("Login failed.");
    return false;
  }
};

const logout = () => {
  localStorage.removeItem('user');
};

const getToken = () => {
  try {
    return JSON.parse(localStorage.getItem('user'));
  } catch (error) {
    console.error("Error getting token:", error);
    return null;
  }
};

const getUserDetails = async (navigate) => {
  const token = await AuthServices.getToken();

  if (!token) {
    navigate("/login");
    toast.error("User is not authenticated.");
    return;
  }

  try {
    const response = await axios.get(`${API_URL}GetLoginUserDetail`, {
      headers: {
        Authorization: `Bearer ${token.data.access_token}`,
      },
    });

    if (response) {
      return response;
    }
  } catch (e) {
    console.error("Error fetching user details:", e);
    AuthServices.logout();
    navigate("/login");
    setTimeout(() => {
      toast.error("Token has expired.");
    }, 300);
  }
};

const isCurrentUser = async (id, navigate) => {
  try {
    const response = await AuthServices.getUserDetails(navigate);

    if (response && response.data && response.data.id === id) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error fetching current user:", error);
    toast.error("Error fetching user details.");
    return false;
  }
};

const updateProfile = async (id, profileData) => {
  const token = AuthServices.getToken();
  if (!token) {
    toast.error("User is not authenticated.");
    return;
  }

  try {
    const response = await axios.post(`${API_URL}UpdateProfile`, { ...profileData, id }, {
      headers: {
        'Content-Type': 'application/json-patch+json',
        Authorization: `Bearer ${token.data.access_token}`
      },
    });

    if (response.status === 200) {
      toast.success('Profile updated successfully.');
    }
    return response.data;
  } catch (error) {
    console.error("Error updating profile:", error);
    toast.error("Profile update failed.");
  }
};

const AuthServices = {
  register,
  socialSignin,
  login,
  logout,
  getToken,
  getUserDetails,
  isCurrentUser,
  updateProfile
};

export default AuthServices;
