import React, { useState, useRef, useEffect } from 'react';
import "../styles/post.css";
import { Button, Form } from 'react-bootstrap';
import { BASE_URL } from '../services/BASE_URL';
import StoriesServices from '../services/StoriesServices';
import { useNavigate } from 'react-router-dom';

function Post({ user, post, likes, commentcollection, medallion, storyId }) {
    console.log(post.mediaType, likes, commentcollection, medallion);

    const hasUserLiked = () => {
        return likes.some(like => like.userId === user.id);
    };

    const [liked, setLiked] = useState(hasUserLiked);
    const navigate = useNavigate();
    const [likeCount, setLikeCount] = useState(likes.length);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [comments, setComments] = useState(commentcollection); // Initialize comments with commentcollection
    const [newComment, setNewComment] = useState("");
    const videoRef = useRef(null);

    const isVideo = post.match(/\.(mp4|webm|ogg)$/i);
    const isImage = post.match(/\.(jpeg|jpg|gif|png)$/i);

    const handlePlayPause = () => {
        const videoElement = videoRef.current;
        if (videoElement) {
            if (videoElement.paused) {
                videoElement.play();
                setIsPlaying(true);
            } else {
                videoElement.pause();
                setIsPlaying(false);
            }
        }
    };

    const toggleComments = () => {
        setShowComments(prevShowComments => !prevShowComments);
    };

    const handleCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const handleCommentSubmit = async () => {
        if (newComment.trim()) {
            await StoriesServices.commentOnStory(storyId, user.id, newComment);
            const newCommentObject = {
                user: { displayName: user.displayName, imagePath: user.imagePath },
                text: newComment,
            };
            setComments([...comments, newCommentObject]);
            setNewComment("");
        }
    };

    const handleLike = async () => {
        setLiked(!liked);
        setLikeCount(prevCount => liked ? prevCount - 1 : prevCount + 1);

        try {
            await StoriesServices.likeStory(storyId);
        } catch (error) {
            // Revert the optimistic update in case of an error
            setLiked(prevLiked => !prevLiked);
            setLikeCount(prevCount => liked ? prevCount + 1 : prevCount - 1);
        }
    };

    return (
        <div className='container post-container mb-5'>
            <div className='mb-2 d-flex align-items-center'>
                <img src={`${BASE_URL}${medallion.imagePath}`} alt='S' className='profile' />
                <div className='ms-2 cursor-pointer' onClick={()=>{
                    navigate(`/d-profile/${medallion.id}`, { state: { medallion } })
                }}>
                    <p className='mb-0 text-white name'>{medallion.displayName}</p>
                    <p className='mb-0 relation'>My Beloved {medallion.relationship}</p>
                </div>
            </div>
            {isImage && <img src={`${BASE_URL}${post}`} alt='Image not Loading' className='w-100 post' />}
            {isVideo && (
                <div className='video-wrapper'>
                    <video className='w-100 post' ref={videoRef}
                        onPlay={() => setIsPlaying(true)}
                        onPause={() => setIsPlaying(false)}
                        controls={isPlaying} >
                        <source src={`${BASE_URL}${post}`} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                    {!isPlaying && (
                        <div className='video-overlay' onClick={handlePlayPause}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-play-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
                            </svg>
                        </div>
                    )}
                </div>
            )}
            <div className='mt-2 d-flex justify-content-between'>
                <div className='text-white like-btn d-flex align-items-center' onClick={handleLike}>
                    {!liked ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-emoji-frown" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.5 3.5 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.5 4.5 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-emoji-frown-fill" viewBox="0 0 16 16">
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m-2.715 5.933a.5.5 0 0 1-.183-.683A4.5 4.5 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 0 1-.866.5A3.5 3.5 0 0 0 8 10.5a3.5 3.5 0 0 0-3.032 1.75.5.5 0 0 1-.683.183M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8" />
                        </svg>
                    )}
                    <p className='p-0 m-0 ps-1 small'>{likeCount}</p>
                </div>
                <div className='text-white like-btn d-flex align-items-center' onClick={toggleComments}>
                    <p className='p-0 m-0 pe-1 small'>{comments.length}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-chat-left-dots" viewBox="0 0 16 16">
                        <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                        <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                </div>
            </div>
            {showComments && (
                <div className='m-2 mt-3'>
                    <div className='comments-list'>
                        {comments.map((comment, index) => (
                            <div key={index} className='comment-item d-flex'>
                                <div className='pb-1'>
                                    <img src={comment.user.imagePath} alt='s' className='comment-profile-img' />
                                </div>
                                <div className='ps-2'>
                                    <p className='comment-text small fw-bold'>{comment.user ? comment.user.displayName : 'Unknown User'}</p>
                                    <p className='comment-text small'>{comment.text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='comments-section'>
                        <Form.Group className="mb-3 w-100" controlId="about">
                            <Form.Control as="textarea" rows={3} placeholder="Add a comment..." className='comment-input' value={newComment} onChange={handleCommentChange} />
                        </Form.Group>
                        <Button className='comment-submit-btn' onClick={handleCommentSubmit}>Post</Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Post;
