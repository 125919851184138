import React, { useState, useEffect } from 'react';
import '../styles/owner-profile.css';
import Header from '../components/Header';
import { ToastContainer } from 'react-toastify';
import { Button } from 'react-bootstrap';
import AuthServices from '../services/AuthServices';
import logout from '../assets/icons/logout.png';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import profile from '../assets/images/person.jpeg';
import QRCodeServices from '../services/QRCodeServices';
import MedallionServices from '../services/MedallionServices';
import DepartedProfiles from '../components/DepartedProfiles';

function ViewDepartedProfiles() {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [medallions, setMedallions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      try {
        const getUser = await AuthServices.getUserDetails(navigate);
        console.log("Get User: ", getUser);
        setUser(getUser.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (user) {
      const fetchMedallions = async () => {
        try {
          const getmedallions = await MedallionServices.getNumberofMedallions(user.id);
          console.log("Get Medallion", getmedallions.data);
          if (getmedallions.data) {
            setMedallions(getmedallions.data);
          }
        } catch (error) {
          console.error("Error checking QR code:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchMedallions();
    }
  }, [user]);

  return (
    <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
      <div className={`screen_container d-flex justify-content-between`} style={{ height: loading ? "84.2vh" : "auto", minHeight: "84.2vh" }}>
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='w-50'>
                <Header />
              </div>
            </div>
            <div className='d-flex flex-column align-items-center mt-3'>
              {user && (
                <>
                  <img src={user.imagePath ? user.imagePath : profile} alt='img' className='profile-image mb-2' />
                  <h4 className='text-white fw-2 text-center w-50'>{user.displayName}</h4>
                  <p className='text-white text-center w-75'>
                    Here are the profiles you have created for the departed individuals.
                  </p>
                </>
              )}
            </div>
            {
              medallions.length > 0 ? <div className='mt-5 d-flex flex-column align-items-center'>
                {medallions.map((medallion, index) => (
                  <DepartedProfiles key={index} img={medallion.imagePath} name={medallion.displayName} relationship={medallion.relationship} id={medallion.id} />
                ))}
              </div> : <div className='w-100 d-flex align-items-center justify-content-center flex-column border-white border-1' style={{ height: "38.1vh" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" fill="white" className="bi bi-emoji-frown" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.5 3.5 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.5 4.5 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5" />
                </svg>
                <h2 className='text-white text-center my-2'>No Profile Available</h2>
              </div>
            }
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewDepartedProfiles;
