import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../styles/deceased-profile.css';
import Header from '../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Button, Modal } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import Loader from '../components/Loader';
import UploadFileServices from '../services/UploadFileServices';
import { useNavigate, useParams } from 'react-router-dom';
import AuthServices from '../services/AuthServices';
import { BASE_URL } from '../services/BASE_URL';
import { getCroppedImg } from '../utils/CropImage';
import edit from '../assets/icons/pen-24.png';
import profile from '../assets/images/person.jpeg';

function EditOwnerProfile() {
  const [user, setUser] = useState(null);
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [cropper, setCropper] = useState({
    showCropper: false,
    crop: { x: 0, y: 0 },
    zoom: 1,
    croppedAreaPixels: null,
    croppedImageFile: null
  });
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      try {
        const response = await AuthServices.getUserDetails(navigate);
        const userData = response.data;
        setUser(userData);
        setFormData({
          name: userData.displayName || '',
          email: userData.email || '',
          password: ''
        });
        setProfileImage(userData.imagePath);
      } catch (error) {
        toast.error('Something went wrong');
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [navigate, id]);

  const validateFields = () => {
    if (!formData.name) {
      toast.error('Name is required.');
      return false;
    }
    if (formData.password && formData.password.length < 6) {
      toast.error('Password should be at least 6 characters long.');
      return false;
    }
    return true;
  };

  const updateProfile = async () => {
    setLoading(true);
    if (!validateFields()) {
      setLoading(false);
      return;
    }

    try {
      let profilepic;
      let path = user.imagePath;

      if (cropper.croppedImageFile) {
        profilepic = await UploadFileServices.uploadFile(cropper.croppedImageFile);
        path = profilepic?.url || path;
      }

      const updatedData = {
        displayName: formData.name,
        email: formData.email,
        imagePath: `${BASE_URL}${path}`,
        ...(formData.password && { password: formData.password }),
      };

      const response = await AuthServices.updateProfile(id, updatedData);

      if (response.statusCode === 200) {
        navigate(-1);
      } else {
        toast.error('Failed to update profile.');
      }
    } catch (error) {
      toast.error('Failed to update profile.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const cancelImage = () => {
    setCropper(prev => ({
      ...prev,
      showCropper: false,
      croppedImageFile: null
    }));
    setProfileImage(user.imagePath);
  };

  const onCropComplete = useCallback(async () => {
    try {
      const { file, url } = await getCroppedImg(profileImage, cropper.croppedAreaPixels);

      const uniqueId = Date.now();
      const fileExtension = file.name.split('.').pop();
      const uniqueFileName = `${formData.name}-${uniqueId}.${fileExtension}`;
      const renamedFile = new File([file], uniqueFileName, { type: file.type });

      setProfileImage(url);
      setCropper(prev => ({
        ...prev,
        croppedImageFile: renamedFile,
        showCropper: false
      }));
    } catch (error) {
      console.error(error);
    }
  }, [cropper.croppedAreaPixels, profileImage, formData.name]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
      setCropper(prev => ({
        ...prev,
        showCropper: true
      }));
    }
  };

  return (
    <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
      <div
        className={`screen_container d-flex`}
        style={{ height: loading ? '84.2vh' : 'auto' }}
      >
        <ToastContainer toastClassName='toaster' />
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='w-50'>
                <Header />
              </div>
            </div>
            <div className='d-flex flex-column align-items-center mt-3'>
              <p className='text-white text-center fs-3 w-50 mt-3 mb-5'>
                Edit Your Profile
              </p>
              <div className='profile-pic-container position-relative'>
                <img
                  src={profileImage || profile}
                  alt='Profile'
                  className='rounded-circle profile-pic'
                />
                <img
                  src={edit}
                  onClick={() => fileInputRef.current.click()}
                  className='edit-icon position-absolute'
                />
              </div>
              <Form className='single-form d-flex flex-column justify-content-center align-items-center w-100 mt-3'>
                <Form.Group className='mb-3 w-100' controlId='name'>
                  <Form.Control
                    type='text'
                    placeholder='Name'
                    className='shopnow-input'
                    value={formData.name}
                    onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  />
                </Form.Group>
                <Form.Group className='mb-3 w-100' controlId='email'>
                  <Form.Control
                    type='email'
                    placeholder='Email'
                    className='shopnow-input'
                    value={formData.email}
                    onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                  />
                </Form.Group>
                <Form.Group className='mb-3 w-100' controlId='password'>
                  <Form.Control
                    type='password'
                    placeholder='New Password'
                    className='shopnow-input'
                    value={formData.password}
                    onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                  />
                </Form.Group>
                <Form.Group className='mb-3 w-100' controlId='imagePath'>
                  <Form.Control
                    type='file'
                    placeholder='Image Path'
                    className='file-input'
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </Form.Group>
                <Button
                  className='paybtn my-2'
                  id='paybtn'
                  type='button'
                  onClick={updateProfile}
                >
                  Update Profile
                </Button>
              </Form>
            </div>
          </div>
        )}
      </div>

      <Modal show={cropper.showCropper} onHide={() => setCropper(prev => ({ ...prev, showCropper: false }))} centered>
        <Modal.Header closeButton>
          <Modal.Title>Crop Your Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='crop-container'>
            <Cropper
              image={profileImage}
              crop={cropper.crop}
              zoom={cropper.zoom}
              aspect={1}
              onCropChange={(crop) => setCropper(prev => ({ ...prev, crop }))}
              onZoomChange={(zoom) => setCropper(prev => ({ ...prev, zoom }))}
              onCropComplete={(croppedArea, croppedAreaPixels) => setCropper(prev => ({ ...prev, croppedAreaPixels }))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='rounded-5' onClick={cancelImage}>
            Cancel
          </Button>
          <Button variant='primary' className='rounded-5 px-3' onClick={onCropComplete}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditOwnerProfile;
