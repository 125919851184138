import React, { useState } from 'react';
import '../styles/shopnow.css';
import Header from '../components/Header';
import { Button, Form } from 'react-bootstrap';
import PaymentServices from '../services/PaymentServices';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../components/Loader';

function ShopNowMulti() {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [profiles, setProfiles] = useState(2);
    const [loading, setLoading] = useState(false);

    const handleNameChange = (e) => setName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleProfilesChange = (e) => setProfiles(e.target.value);

    const pay = async () => {
        setLoading(true);
        try {
            if (name.trim() === "" || email.trim() === "") {
                toast.error('Please fill in all required fields');
                setLoading(false);
                return;
            }
            if (profiles < 2) {
                toast.error('Please add 2 or more profiles');
                setLoading(false);
                return;
            }

            const data = {
                userName: name.trim(),
                userEmail: email.trim(),
                packageType: 1,
                numberOfQRCodes: profiles,
                amount: 59,
                isPaid: true,
            };

            const response = await PaymentServices.initiatePayment(data);

            if (response.status) {
                navigate("/profile");
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error('An error occurred during payment. Please try again.');
            console.error("Payment Error: ", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <div className='screen_container' style={{ height: loading ? "84.2vh" : "auto" }}>
                <ToastContainer toastClassName='toaster' />

                {loading ? <Loader /> : (
                    <div>
                        <Header />
                        <div className='gradientBox h-auto pe-3 py-2 mt-3 mb-0 cursor-none single-package'>
                            <div className='section1'>
                                <p className='fs-4 text-uppercase text-white mb-0'>Multi 2+</p>
                                <p className='text-white light mb-0'>Create a memory for two or more loved ones</p>
                            </div>
                            <div className='section2'>
                                <p className='text-white mb-0 fw-3'>$59</p>
                            </div>
                        </div>
                        <div className='d-flex flex-column align-items-center pt-3 single-package-bg'>
                            <p className='text-white text-center w-75'>You are buying two or more owner packages and unique barcodes. The barcode will be sent to you to place on the headstone.</p>
                            <Form className='single-form d-flex flex-column justify-content-center align-items-center'>
                                <Form.Group className="mb-3 w-100" controlId="name">
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        className='shopnow-input'
                                        value={name}
                                        onChange={handleNameChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 w-100" controlId="email">
                                    <Form.Control
                                        type="email"
                                        placeholder="Email Address"
                                        className='shopnow-input'
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3 w-100" controlId="profiles">
                                    <Form.Control
                                        type="number"
                                        placeholder="Number of profiles"
                                        className='shopnow-input'
                                        value={profiles}
                                        onChange={handleProfilesChange}
                                        min={2}
                                    />
                                </Form.Group>
                                <Button
                                    className='paybtn my-2'
                                    id="paybtn"
                                    type="button"
                                    onClick={pay}
                                >
                                    Pay
                                </Button>
                            </Form>
                            <p className='text-white text-center w-75 mt-2 light'>One-time lifetime payment, no subscription.</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ShopNowMulti;
