import React, { useState, useRef } from 'react'
import '../styles/shopnow.css'
import Header from '../components/Header';
import { Button, Form } from 'react-bootstrap';

function ShopNowCommercial() {

    const DOBref = useRef();
    const DODref = useRef();


    const [name, setName] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const [departedName, setDepartedName] = useState('');

    const handleDepartedNameChange = (e) => {
        setDepartedName(e.target.value);
    };

    const [dob, setDOB] = useState('');

    const handleDOBChange = (e) => {
        setDOB(e.target.value);
    };

    const [dod, setDOD] = useState('');

    const handleDODChange = (e) => {
        setDOD(e.target.value);
    };

    const [age, setAge] = useState('');

    const handleAgeChange = (e) => {
        setAge(e.target.value);
    };

    const [burialLocation, setBurialLocation] = useState('');

    const handleBurialLocationChange = (e) => {
        setBurialLocation(e.target.value);
    };

    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const [companyName, setCompanyName] = useState('');

    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
    };

    const [companyEmail, setCompanyEmail] = useState('');

    const handleCompanyEmailChange = (e) => {
        setCompanyEmail(e.target.value);
    };

    const [companyAddress, setCompanyAddress] = useState('');

    const handleCompanyAddressChange = (e) => {
        setCompanyAddress(e.target.value);
    };

    const [nloved, setNLoved] = useState('');

    const handleNLovedChange = (e) => {
        setNLoved(e.target.value);
    };

    const [place, setPlace] = useState('');

    const handlePlaceChange = (e) => {
        setPlace(e.target.value);
    };

    const [ownerName, setOwnerName] = useState('');

    const handleOwnerNameChange = (e) => {
        setOwnerName(e.target.value);
    };
    const [ownerEmail, setOwnerEmail] = useState('');

    const handleOwnerEmail = (e) => {
        setOwnerEmail(e.target.value);
    };

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <div className='screen_container h-auto'>
                <Header />
                <div className='gradientBox h-auto pe-3 py-2 mt-3 mb-0 cursor-none single-package'>
                    <div className='section1'>
                        <p className='fs-4 text-uppercase text-white mb-0'>Commercial</p>
                        <p className='text-white light mb-0'>For Bulk orders, Cemeteries, Stone masons, Funeral Homes...</p>
                    </div>
                    <div className='section2'>
                        <p className='text-white mb-0 fw-3'>$908</p>
                    </div>
                </div>
                <div className='d-flex flex-column align-items-center pt-3 single-package-bg'>
                    <p className='text-white text-center w-75'>You are buying two or more owner packages and unique barcodes. The barcode will be sent to you to place on headstone.</p>
                    <p className='text-white w-75 text-start'>Steps</p>
                    <ol>
                        <li className='text-white text-start'>Complete Details for each client</li>
                        <li className='text-white text-start'>Scan each code.</li>
                        <li className='text-white text-start'>Click Submit.</li>
                    </ol>
                    <Form className='single-form d-flex flex-column justify-content-center align-items-center'>
                        <Form.Group className="mb-3 w-100" controlId="name">
                            <Form.Control type="text" placeholder="Name" className='shopnow-input' value={name} onChange={handleNameChange} />
                        </Form.Group>
                        <Form.Group className="mb-3 w-100" controlId="email">
                            <Form.Control type="email" placeholder="Email Address" className='shopnow-input' value={email} onChange={handleEmailChange} />
                        </Form.Group>
                        <Form.Group className="mb-3 w-100" controlId="name-loved-one">
                            <Form.Control type="text" placeholder="Name of loved one" className='shopnow-input' value={nloved} onChange={handleNLovedChange} />
                        </Form.Group>
                        <Form.Group className="mb-3 w-100" controlId="name-loved-one">
                            <Form.Control as="textarea" rows={3} placeholder="Place where loved one is buried" className='shopnow-input' value={place} onChange={handlePlaceChange} />
                        </Form.Group>

                        {/* Company Details */}
                        <p className='text-white w-100 text-start'>Company Details</p>
                        <Form.Group className="mb-3 w-100" controlId="companyname">
                            <Form.Control type="text" placeholder="Company Name" className='shopnow-input' value={companyName} onChange={handleCompanyNameChange} />
                        </Form.Group>
                        <Form.Group className="mb-3 w-100" controlId="companyaddress">
                            <Form.Control as="textarea" rows={3} placeholder="Address" className='shopnow-input' value={companyAddress} onChange={handleCompanyAddressChange} />
                        </Form.Group>
                        <Form.Group className="mb-3 w-100" controlId="companyemail">
                            <Form.Control type="email" placeholder="Email Address" className='shopnow-input' value={companyEmail} onChange={handleCompanyEmailChange} />
                        </Form.Group>

                        {/* Departed Details */}
                        <p className='text-white w-100 text-start'>Departed Details</p>
                        <Form.Group className="mb-3 w-100" controlId="departedname">
                            <Form.Control type="text" placeholder="Departed Name" className='shopnow-input' value={departedName} onChange={handleDepartedNameChange} />
                        </Form.Group>
                        <div className='row'>
                            <Form.Group className="mb-3 col-lg-5" controlId="companyaddress">
                                <Form.Control ref={DOBref} type="text" placeholder="Date of Birth" className='shopnow-input' value={dob} onChange={handleDOBChange} onFocus={() => (DOBref.current.type = "date")}
                                    onBlur={() => (DOBref.current.type = "text")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-5" controlId="companyemail">
                                <Form.Control ref={DODref} type="text" placeholder="Date of Death" className='shopnow-input' value={dod} onChange={handleDODChange} onFocus={() => (DODref.current.type = "date")}
                                    onBlur={() => (DODref.current.type = "text")} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-lg-2 ps-0" controlId="companyemail">
                                <Form.Control type="text" placeholder="Age" className='shopnow-input' value={age} onChange={handleAgeChange} />
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3 w-100" controlId="burial-location">
                            <Form.Control as="textarea" rows={3} placeholder="Burial Location/Allotment" className='shopnow-input' value={burialLocation} onChange={handleBurialLocationChange} />
                        </Form.Group>

                        {/* Owner Details */}
                        <p className='text-white w-100 text-start'>Owner Details</p>
                        <Form.Group className="mb-3 w-100" controlId="ownername">
                            <Form.Control type="text" placeholder="Full Name" className='shopnow-input' value={ownerName} onChange={handleOwnerNameChange} />
                        </Form.Group>
                        <Form.Group className="mb-3 w-100" controlId="owneremail">
                            <Form.Control type="email" placeholder="Email Address" className='shopnow-input' value={ownerEmail} onChange={handleOwnerEmail} />
                        </Form.Group>

                        <div className='w-100 d-flex justify-content-evenly'>
                            <Button className='paybtn my-2 w-auto' id="paybtn" type="submit" onClick={() => {
                                // navigate('/register');
                            }}>
                                Scan Code
                            </Button>
                            <Button className='paybtn my-2 w-auto' id="paybtn" type="submit" onClick={() => {
                                // navigate('/register');
                            }}>
                                Submit
                            </Button>
                        </div>
                    </Form>
                    <p className='text-white text-center w-75 mt-2 light'>On off lifetime payment, no subscription.</p>
                </div>
            </div>
        </div>
    )
}

export default ShopNowCommercial
