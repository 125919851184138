import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SplashScreen from '../screens/SplashScreen';
import Login from '../screens/LoginScreen';
import ShowStory from '../screens/ShowStory';
import Signup from '../screens/SignupScreen';
import ForgetPwd from '../screens/ForgetPwd';
import ShopNow from '../screens/ShopNow';
import ShopNowSingle from '../screens/ShopNowSingle';
import ShopNowMulti from '../screens/ShopNowMulti';
import ShopNowCommercial from '../screens/ShopNowCommercial';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import OwnerProfileScreen from '../screens/OwnerProfileScreen';
import DeceasedProfile from '../screens/DeceasedProfile';
import FeedScreen from '../screens/FeedScreen';
import DeceasedProfileScreen from '../screens/DeceasedProfileScreen';
import ViewDepartedProfiles from '../screens/ViewDepartedProfiles';
import EditDeceasedProfile from '../screens/EditDeceasedProfile';
import ViewCodeScreen from '../screens/ViewCodeScreen';
import ViewQRCodeScreen from '../screens/ViewQRCodeScreen';
import EditOwnerProfile from '../screens/EditOwnerProfile';
import QRRedirectScreen from '../screens/QRRedirectScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import ReviewRequest from '../screens/ReviewRequest';
import RequestScreen from '../screens/RequestsScreen';
import DeclinedRequest from '../screens/DeclinedRequest';

function Navigator() {
    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<SplashScreen />} />
                <Route path="/d-profile/:id" element={<DeceasedProfileScreen />} />
                <Route path="/login" element={<PublicRoute element={Login} />} />
                <Route path="/register" element={<PublicRoute element={Signup} />} />
                <Route path="/forget-password" element={<PublicRoute element={ForgetPwd} />} />
                <Route path="/home" element={<PrivateRoute element={FeedScreen} />} />
                <Route path="/deceased-profile" element={<PrivateRoute element={DeceasedProfile} />} />
                <Route path="/redirect/:uuid" element={<QRRedirectScreen />} />
                <Route path="/request/:id" element={<PrivateRoute element={ReviewRequest} />} />
                <Route path="/view-deceased-profile" element={<PrivateRoute element={ViewDepartedProfiles} />} />
                <Route path="/notifications" element={<PrivateRoute element={NotificationsScreen} />} />
                <Route path="/requests" element={<PrivateRoute element={RequestScreen} />} />
                <Route path="/declined-request" element={<PrivateRoute element={DeclinedRequest} />} />
                <Route path="/view-QR-codes" element={<PrivateRoute element={ViewQRCodeScreen} />} />
                <Route path="/edit-deceased-profile/:id" element={<PrivateRoute element={EditDeceasedProfile} />} />
                <Route path="/edit-profile/:id" element={<PrivateRoute element={EditOwnerProfile} />} />
                <Route path="/QR/:uuid" element={<PrivateRoute element={ViewCodeScreen} />} />
                <Route path="/profile" element={<PrivateRoute element={OwnerProfileScreen} />} />
                <Route path="/story" element={<PrivateRoute element={ShowStory} />} />
                <Route path="/shop-now" element={<PrivateRoute element={ShopNow} />} />
                <Route path="/shop-now/single" element={<PrivateRoute element={ShopNowSingle} />} />
                <Route path="/shop-now/multi" element={<PrivateRoute element={ShopNowMulti} />} />
                <Route path="/shop-now/commercial" element={<PrivateRoute element={ShopNowCommercial} />} />
            </Routes>
        </Router>
    );
}

export default Navigator;
