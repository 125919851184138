import React, { useEffect, useState } from 'react'
import QRCodeServices from '../services/QRCodeServices';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../services/BASE_URL';
import { ToastContainer } from 'react-toastify';
import Header from '../components/Header';
import Loader from '../components/Loader';
import "../styles/variables.css";

function ViewCodeScreen() {
    const { uuid } = useParams();
    const [loading, setLoading] = useState(false);
    const [img, setImg] = useState('');

    useEffect(() => {
        const getQRCode = async () => {
            setLoading(true);
            const qr = await QRCodeServices.getQrCodeByGuId(uuid);
            console.log(qr);
            if (qr) {
                setImg(`${BASE_URL}${qr.imagePath}`)
                setLoading(false)
            }
        }

        getQRCode();
    }, []);


    return (
        <div className='main_container' style={{ height: "100vh" }}>
            <div className={`screen_container d-flex justify-content-between`} style={{ height: "81vh" }}>
                {loading ? (
                    <Loader />
                ) : (
                    <div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <Header />
                        </div>
                        <ToastContainer toastClassName='toaster' />
                        <div className='d-flex flex-column'>
                            <img src={img} className='handleQR mt-3 align-self-center' />
                            <p className='small text-secondary text-center pt-1'>{uuid}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ViewCodeScreen