import React, { useState, useEffect } from 'react';
import '../styles/owner-profile.css';
import Header from '../components/Header';
import { useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import ProfileRequestNotification from '../components/ProfileRequestNotification';
import NotificationServices from '../services/NotificationsServices';
import { ToastContainer, toast } from 'react-toastify';

function NotificationsScreen() {
    const location = useLocation();

    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true); 

    const getNotifications = async () => {
        try {
            const response = await NotificationServices.getNotifications(12);
            console.log("Get Notifications: ", response);
            setNotifications(response.data);
        } catch (error) {
            console.error("Error fetching notifications:", error);
            toast.error("Failed to load notifications.");
        } finally {
            setLoading(false); // Ensure loading is set to false
        }
    };

    useEffect(() => {
        getNotifications();
    }, []);

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <ToastContainer toastClassName='toaster' />
            <div
                className='screen_container d-flex justify-content-between'
                style={{ height: loading ? "84.2vh" : "auto", minHeight: "84.2vh" }}
            >
                {loading ? (
                    <Loader />
                ) : (
                    <div>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <div className='w-50'>
                                <Header />
                            </div>
                        </div>
                        {notifications.length > 0 ? (
                            notifications.map((notification) => (
                                <ProfileRequestNotification
                                    key={notification.id} 
                                    notification={notification}
                                    user={location.state?.user} 
                                />
                            ))
                        ) : (
                            <p className='text-white text-center'>No notifications available.</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default NotificationsScreen;
