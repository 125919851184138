import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../styles/deceased-profile.css';
import Header from '../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import { Form, Button, Modal } from 'react-bootstrap';
import MedallionServices from '../services/MedallionServices';
import Loader from '../components/Loader';
import UploadFileServices from '../services/UploadFileServices';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getCroppedImg } from '../utils/CropImage';
import Cropper from 'react-easy-crop';
import edit from '../assets/icons/pen-24.png';

import profile from '../assets/images/person.jpeg';
import banner from '../assets/images/banner.jpg';
import Profile from '../components/profile/Profile';
import { BASE_URL } from '../services/BASE_URL';


function EditDeceasedProfile() {
    const [user, setUser] = useState(null);
    const { id } = useParams();
    const [name, setName] = useState('');
    const [relation, setRelation] = useState('');
    const [headline, setHeadline] = useState('');
    const [dob, setDob] = useState('');
    const [dod, setDod] = useState('');
    const [about, setAbout] = useState('');
    const [profileStatus, setProfileStatus] = useState('');
    const [qrcode, setQrCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [showProfileCropper, setShowProfileCropper] = useState(false);
    const [showBannerCropper, setShowBannerCropper] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [cropProfile, setProfileCrop] = useState({ x: 0, y: 0 });
    const [zoomProfile, setProfileZoom] = useState(1);
    const [croppedProfileAreaPixels, setCroppedProfileAreaPixels] = useState(null);
    const [croppedProfileImageFile, setCroppedProfileImageFile] = useState(null);
    const [cropBanner, setBannerCrop] = useState({ x: 0, y: 0 });
    const [zoomBanner, setBannerZoom] = useState(1);
    const [croppedBannerAreaPixels, setCroppedBannerAreaPixels] = useState(null);
    const [croppedBannerImageFile, setCroppedBannerImageFile] = useState(null);
    const fileInputRef = useRef(null);
    const thumbnailInputRef = useRef(null);

    const navigate = useNavigate();

    const formatDate = (dateString) => dateString.split('T')[0];

    const onCropProfileComplete = useCallback(async () => {
        try {
            const { file, url } = await getCroppedImg(profileImage, croppedProfileAreaPixels);
            const uniqueId = Date.now();
            const fileExtension = file.name.split('.').pop();
            const uniqueFileName = `${name}-${uniqueId}.${fileExtension}`;
            const renamedFile = new File([file], uniqueFileName, { type: file.type });
            setProfileImage(url);
            setCroppedProfileImageFile(renamedFile);
            setShowProfileCropper(false);
        } catch (error) {
            console.error(error);
        }
    }, [croppedProfileAreaPixels, profileImage, name]);

    const onCropBannerComplete = useCallback(async () => {
        try {
            const { file, url } = await getCroppedImg(bannerImage, croppedBannerAreaPixels);
            const uniqueId = Date.now();
            const fileExtension = file.name.split('.').pop();
            const uniqueFileName = `${name}-${uniqueId}.${fileExtension}`;
            const renamedFile = new File([file], uniqueFileName, { type: file.type });
            setBannerImage(url);
            setCroppedBannerImageFile(renamedFile);
            setShowBannerCropper(false);
        } catch (error) {
            console.error(error);
        }
    }, [croppedBannerAreaPixels, bannerImage]);



    useEffect(() => {
        const fetchUserDetails = async () => {
            setLoading(true);
            try {
                const response = await MedallionServices.getMedallionByID(id);
                console.log("Get Medallion: ", response);
                const userData = response.data;
                setUser(userData);
                setName(userData.displayName || '');
                setRelation(userData.relationship || '');
                setHeadline(userData.bioInformation || '');
                setDob(formatDate(userData.dateOfBirth) || '');
                setDod(formatDate(userData.dateOfDeath) || '');
                setAbout(userData.about || '');
                setProfileImage(`${BASE_URL}${userData.imagePath}` || profile);
                setBannerImage(`${BASE_URL}${userData.thumbNailPath}` || banner);
                setProfileStatus(userData.isPrivate ? 'private' : 'public');
                setQrCode(userData.qrCodeId || '');
            } catch (error) {
                console.error("Error fetching user details:", error);
                setTimeout(() => {
                    toast.error('Something went wrong');
                }, 300);
                navigate(-1);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [navigate]);

    const validateFields = () => {
        if (!name) {
            toast.error('Name is required.');
            return false;
        }
        if (!headline) {
            toast.error('Headline is required.');
            return false;
        }
        if (!dob) {
            toast.error('Date of birth is required.');
            return false;
        }
        if (!dod) {
            toast.error('Date of death is required.');
            return false;
        }
        if (!about) {
            toast.error('About section is required.');
            return false;
        }
        if (!profileStatus) {
            toast.error('Profile status is required.');
            return false;
        }
        if (!qrcode) {
            toast.error('QR Code is required.');
            return false;
        }
        return true;
    };

    const updateDeceasedProfile = async () => {
        setLoading(true);
        if (!validateFields()) {
            setLoading(false);
            return;
        }

        try {
            const imageFile = fileInputRef.current?.files[0];
            const thumbnailFile = thumbnailInputRef.current?.files[0];
            let profilepic, thumbnailpic;
            if (croppedProfileImageFile || imageFile) {
                profilepic = await UploadFileServices.uploadFile(croppedProfileImageFile || imageFile);
            }
            if (croppedBannerImageFile || thumbnailFile) {
                thumbnailpic = await UploadFileServices.uploadFile(croppedBannerImageFile || thumbnailFile);
            }

            const updatedData = {
                displayName: name,
                relationship: relation,
                imagePath: profilepic?.url || user.imagePath,
                thumbNailPath: thumbnailpic?.url || user.thumbNailPath,
                bioInformation: headline,
                about: about,
                isPrivate: profileStatus === 'private',
                dateOfBirth: dob,
                dateOfDeath: dod,
                qrCodeId: qrcode
            };

            const response = await MedallionServices.updateMedallion(id, updatedData);

            if (response.data.statusCode == 200) {
                navigate(-1);
            }
        } catch (error) {
            toast.error('Failed to update profile.');
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false);
        }
    };

    console.log(dob, dod);

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <div className={`screen_container d-flex justify-content-between`} style={{ height: loading ? "84.2vh" : "auto" }}>
                <ToastContainer toastClassName='toaster' />

                {loading ? (
                    <Loader />
                ) : (
                    <div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='w-50'>
                                <Header />
                            </div>
                        </div>
                        <div className='d-flex flex-column align-items-center mt-3'>
                            <p className='text-white text-center fs-3 w-50 mt-3 mb-5 '>
                                Edit the Profile of Your Loved One
                            </p>
                            <Form className='single-form d-flex flex-column justify-content-center align-items-center w-100'>
                                <div className='position-relative mb-5 d-flex flex-column align-items-center w-100'>
                                    <div className='banner-container position-relative mb-3'>
                                        <img
                                            src={`${bannerImage}`}
                                            alt='Banner'
                                            className='rounded-3 banner'
                                        />
                                        <img
                                            src={edit}
                                            onClick={() => thumbnailInputRef.current.click()}
                                            className='edit-icon-banner position-absolute'
                                        />
                                    </div>
                                    <div className='profile-pic-container position-absolute mb-3' style={{ top: 100 }}>
                                        <img
                                            src={`${profileImage}`}
                                            alt='Profile'
                                            className='rounded-circle profile-pic'
                                        />
                                        <img
                                            src={edit}
                                            onClick={() => fileInputRef.current.click()}
                                            className='edit-icon position-absolute'
                                        />
                                    </div>
                                </div>
                                <Form.Group className="mb-3 w-100" controlId="name">
                                    <Form.Control type="text" placeholder="Name" className='shopnow-input' value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3 w-100" controlId="headline">
                                    <Form.Control as="textarea" rows={3} placeholder="Bio Information" className='shopnow-input' value={headline} onChange={(e) => setHeadline(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3 w-100" controlId="relationship">
                                    <Form.Control type="text" placeholder="Relationship" className='shopnow-input' value={relation} onChange={(e) => setRelation(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3 w-100" controlId="imagePath">
                                    <Form.Control type="file" placeholder="Image Path" className='file-input' ref={fileInputRef} onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            const reader = new FileReader();
                                            reader.onloadend = () => {
                                                setProfileImage(reader.result);
                                                setShowProfileCropper(true);
                                            };
                                            reader.readAsDataURL(file);
                                        }
                                    }}
                                        style={{ display: 'none' }} />
                                </Form.Group>
                                <Form.Group className="mb-3 w-100" controlId="thumbnailPath">
                                    <Form.Control type="file" placeholder="Thumbnail Path" className='file-input' ref={thumbnailInputRef} onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            const reader = new FileReader();
                                            reader.onloadend = () => {
                                                setBannerImage(reader.result);
                                                setShowBannerCropper(true);
                                            };
                                            reader.readAsDataURL(file);
                                        }
                                    }}
                                        style={{ display: 'none' }} />
                                </Form.Group>
                                <Form.Group className="mb-3 w-100" controlId="dob">
                                    <Form.Label for="dob" className='text-white pb-2'>Date of Birth</Form.Label>
                                    <Form.Control type="date" placeholder="Date of Birth" className='shopnow-input' value={dob} onChange={(e) => setDob(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3 w-100" controlId="dod">
                                    <Form.Label for="dod" className='text-white pb-2'>Date of Death</Form.Label>
                                    <Form.Control type="date" placeholder="Date of Death" className='shopnow-input' value={dod} onChange={(e) => setDod(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3 w-100" controlId="about">
                                    <Form.Control as="textarea" rows={3} placeholder="About" className='shopnow-input' value={about} onChange={(e) => setAbout(e.target.value)} />
                                </Form.Group>
                                <Form.Group className="mb-3 w-100" controlId="profileStatus">
                                    <Form.Select
                                        className='shopnow-input'
                                        value={profileStatus}
                                        onChange={(e) => setProfileStatus(e.target.value)}
                                    >
                                        <option value="">Choose Profile Status</option>
                                        <option value="public">Public</option>
                                        <option value="private">Private</option>
                                    </Form.Select>
                                </Form.Group>
                                {/* <Form.Group className="mb-3 w-100" controlId="qrcode">
                  <Form.Select
                    className='shopnow-input'
                    value={qrcode}
                    onChange={(e) => setQrCode(e.target.value)}
                  >
                    <option value="">Choose QR Code</option>
                    {qrCodes.map((qr, index) => {
                      return <option key={index} value={qr.uniqueIdentifier}>{qr.uniqueIdentifier}</option>
                    })}
                  </Form.Select>
                </Form.Group> */}

                                <Button className='paybtn my-2' id="paybtn" type="button" onClick={updateDeceasedProfile}>
                                    Update Profile
                                </Button>
                            </Form>
                        </div>
                    </div>
                )}
            </div>
            <Modal show={showProfileCropper} onHide={() => setShowProfileCropper(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Crop Profile Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='crop-container'>
                        <Cropper
                            image={profileImage}
                            crop={cropProfile}
                            zoom={zoomProfile}
                            aspect={1}
                            onCropChange={setProfileCrop}
                            onZoomChange={setProfileZoom}
                            onCropComplete={(_, croppedAreaPixels) => setCroppedProfileAreaPixels(croppedAreaPixels)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {
                        setShowProfileCropper(false);
                        setProfileImage(`${BASE_URL}${user.imagePath}` || profile)
                    }}>
                        Close
                    </Button>
                    <Button variant='primary' onClick={onCropProfileComplete}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showBannerCropper} onHide={() => setShowBannerCropper(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Crop Banner Picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='crop-container'>
                        <Cropper
                            image={bannerImage}
                            crop={cropBanner}
                            zoom={zoomBanner}
                            aspect={2.5}
                            onCropChange={setBannerCrop}
                            onZoomChange={setBannerZoom}
                            onCropComplete={(_, croppedAreaPixels) => setCroppedBannerAreaPixels(croppedAreaPixels)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {
                        setShowBannerCropper(false);
                        setBannerImage(`${BASE_URL}${user.thumbNailPath}` || banner);
                    }}>
                        Close
                    </Button>
                    <Button variant='primary' onClick={onCropBannerComplete}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default EditDeceasedProfile;
