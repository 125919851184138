import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useDispatch } from "react-redux";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { height } from "../constants/Size";
import { toast } from "react-toastify";
import UploadFileServices from "../services/UploadFileServices";
import StoriesServices from "../services/StoriesServices";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const ModalContent = styled.div`
  background: #262626;
  padding: 20px;
  border-radius: 10px;
  max-width: 34%;
  width: 100%;
  animation: ${fadeIn} 0.5s ease-in-out;
  @media only screen and (min-width: 768px) and (max-width: 959px) {
      max-width: 50%;
  }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
      max-width: 70%;
  }
  @media only screen and (min-width: 320px) and (max-width: 479px) {
      max-width: 90%;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const ModalTitle = styled.h2`
  margin: 0 auto;
  color: white;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #686868;
  color: white;
  cursor: pointer;
  text-align: center;
  margin-bottom: 30px;
  width: 40%;

  &:hover {
    background: #686868;
  }
`;

const Preview = styled.div`
  margin-bottom: 30px;
  width: 100%;
  text-align: center;

  img,
  video {
    max-width: 100%;
    max-height: 300px;
  }
`;

const Loader = styled.div`
  border: 12px solid #f3f3f3; 
  border-top: 12px solid #A1281F; 
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 0.6s linear infinite;
  margin-bottom: 30px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SubmitButton = styled.button`
  padding: 10px;
  border: none;
  border-radius: 30px;
  width: 30%;
  background: #A1281F;
  color: white;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background: #A1281F;
  }
`;

const UploadStory = ({ isOpen, onClose, medallionId }) => {
  const [story, setStory] = useState({ file: null });
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const file = e.target.files[0];
    setError('');
    setStory({ file });

    if (file) {
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(file);

      videoElement.onloadedmetadata = () => {
        if (file.type.startsWith('video') && videoElement.duration > 30) {
          setError('Video duration must not exceed 30 seconds.');
          setStory({ file: null });
          setPreview(null);
        } else {
          setLoading(true);
          const reader = new FileReader();
          reader.onloadend = () => {
            setPreview(reader.result);
            setLoading(false);
          };
          reader.readAsDataURL(file);
        }
      };

      if (file.type.startsWith('image')) {
        setLoading(true);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
          setLoading(false);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setPreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (story.file) {
      setLoading(true);
      try {
        const uploadFile = await UploadFileServices.uploadFile(story.file);
        if (uploadFile) {
          const createStory = await StoriesServices.createStory(medallionId, uploadFile.url);
          console.log(createStory);
          if (createStory) {
            window.location.reload();
          } else {
            toast.error("Something went wrong while uploading Story");
          }
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        toast.error('Failed to upload file.');
      } finally {
        setLoading(false);
        onClose();
      }
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setStory({ file: null });
      setPreview(null);
      setLoading(false);
      setError('');
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <ModalBackground>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>Upload Story</ModalTitle>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <Form onSubmit={handleSubmit}>
          {loading ? (
            <Loader />
          ) : preview ? (
            <Preview>
              {story.file.type.startsWith("image") ? (
                <img src={preview} alt="Preview" />
              ) : (
                <video controls>
                  <source src={preview} type={story.file.type} />
                  Your browser does not support the video tag.
                </video>
              )}
            </Preview>
          ) : (
            <>
              <FileInputLabel htmlFor="file-upload">
                <AiOutlineCloudUpload size={height * 0.1} />
                <p>Choose File</p>
              </FileInputLabel>
              <FileInput
                type="file"
                id="file-upload"
                name="file"
                accept="image/*,video/*"
                onChange={handleChange}
                required
              />
            </>
          )}
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <SubmitButton type="submit">Upload</SubmitButton>
        </Form>
      </ModalContent>
    </ModalBackground>
  );
};

export default UploadStory;
