import React, { useState, useEffect } from 'react';
import '../styles/owner-profile.css';
import Header from '../components/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import RequestNotification from '../components/RequestNotification';
import RequestServices from '../services/RequestServices';
import { formatDateTime } from '../functions/CommonFunctions';
import { toast, ToastContainer } from 'react-toastify';

function RequestScreen() {
    const navigate = useNavigate();
    const location = useLocation();
    const userId = location.state?.user?.id;

    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    const getRequests = async () => {
        try {
            if (userId) {
                const response = await RequestServices.getRequests(userId);
                console.log("Get Requests: ", response);
                setRequests(response);
            } else {
                toast.error("User ID is not available.");
            }
        } catch (error) {
            console.error("Error fetching requests:", error);
            toast.error("Failed to fetch requests.");
        } finally {
            setLoading(false);
        }
    };

    const handleAllow = async (requestId) => {
        setLoading(true);
        try {
            await RequestServices.ApproveRequest(requestId);
            setRequests(prevRequests => prevRequests.filter(request => request.id !== requestId));
            toast.success("Request Accepted Successfully");
        } catch (error) {
            console.error("Error allowing request:", error);
            toast.error("Failed to accept request.");
        } finally {
            setLoading(false);
        }
    };

    const handleDecline = async (requestId) => {
        setLoading(true);
        try {
            await RequestServices.DeclineRequest(requestId);
            setRequests(prevRequests => prevRequests.filter(request => request.id !== requestId));
            toast.success("Request Declined Successfully");
        } catch (error) {
            console.error("Error declining request:", error);
            toast.error("Failed to decline request.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getRequests();
    }, [userId]); 

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <ToastContainer toastClassName='toaster' />
            <div className={`screen_container d-flex justify-content-between`} style={{ height: loading ? "84.2vh" : "auto", minHeight: "84.2vh" }}>
                {loading ? (
                    <Loader />
                ) : (
                    <div>
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <div className='w-50'>
                                <Header />
                            </div>
                        </div>
                        {requests.length > 0 ? (
                            requests.map((request) => (
                                <RequestNotification
                                    key={request.id}
                                    message={`${request.requestingUser.displayName} wants to view ${request.medallion.displayName}'s profile.`}
                                    requester={request.requestingUser}
                                    requestTime={formatDateTime(request.requestDate)}
                                    onAllow={() => handleAllow(request.id)}
                                    onDecline={() => handleDecline(request.id)}
                                />
                            ))
                        ) : (
                            <p className='text-white text-center'>No requests available.</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default RequestScreen;
