import axios from 'axios';
import { BASE_URL } from './BASE_URL';
import AuthServices from './AuthServices';

const API_URL = `${BASE_URL}/api/Stories`;
const token = AuthServices.getToken();

const getStories = async (pgno) => {
    try {
        const response = await axios.post(
            `${API_URL}/PaginationList`,
            {
                pageNumber: pgno,
                pageSize: 50,
            },
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error getting stories:', error);
        return false;
    }
};

const createStory = async (medallionId, url) => {
    try {
        const response = await axios.post(
            `${API_URL}/Create`,
            {
                filePath: url,
                medallionId: medallionId 
            },
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error creating story:', error);
        return false;
    }
}

const likeStory = async (storyid) => {
    try {
        const response = await axios.post(
            `${API_URL}/LikeDisLikeStory?storyId=${storyid}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.status;
    } catch (error) {
        console.error('Error liking story:', error);
        return false;
    }
}

const commentOnStory = async (storyid, userid, comment) => {
    try {
        const response = await axios.post(
            `${API_URL}/CommentOnStory`,
            {
                storyId: storyid,
                userId: userid,
                text: comment
            },
            {
                headers: {
                    Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error commenting on story:', error);
        return false;
    }
}

const getStoriesByID = async (id) => {
    try {
        const response = await axios.get(
            `${API_URL}/GetStoriesOfMedallion?Id=${id}`,
            {
                headers: {
                    // Authorization: `Bearer ${token.data.access_token}`
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error getting stories by ID:', error);
        return false;
    }
};

const StoriesServices = {
    getStories, 
    createStory, 
    likeStory, 
    commentOnStory, 
    getStoriesByID
};

export default StoriesServices;
