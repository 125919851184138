import React, { useState } from 'react';
import '../styles/owner-profile.css';
import Header from '../components/Header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import MedallionServices from '../services/MedallionServices';
import { toast, ToastContainer } from 'react-toastify';
import { Button } from 'react-bootstrap';
import defaultProfileImage from '../assets/images/person.jpeg';
import allow from '../assets/icons/checked.png';
import decline from '../assets/icons/decline.png';

function ReviewRequest() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const requester = location.state?.requester;
    const message = location.state?.message;

    const [loading, setLoading] = useState(false);

    const handleRequest = async (action) => {
        setLoading(true);
        try {
            const response = action === 'approve'
                ? await MedallionServices.ApproveRequest(params.id)
                : await MedallionServices.DeclineRequest(params.id);

            if (response.data.statusCode === 200) {
                toast.success(`Request ${action === 'approve' ? 'Approved' : 'Declined'} Successfully`);
                navigate('/requests'); 
            } else {
                toast.error(`Failed to ${action === 'approve' ? 'approve' : 'decline'} the request.`);
            }
        } catch (error) {
            console.error(`Error ${action === 'approve' ? 'approving' : 'declining'} request:`, error);
            toast.error(`Error ${action === 'approve' ? 'approving' : 'declining'} request.`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <ToastContainer toastClassName='toaster' />
            <div className={`screen_container d-flex justify-content-between`} style={{ height: loading ? "84.2vh" : "auto", minHeight: "84.2vh" }}>
                {loading ? (
                    <Loader />
                ) : (
                    <div>
                        <div className='d-flex justify-content-between align-items-center mb-3 w-100'>
                            <div className='w-50'>
                                <Header />
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "65vh" }}>
                            <img src={requester?.imagePath || defaultProfileImage} alt='Profile' className='profile-image mb-2' />
                            <Button className='w-50' style={{ background: "none", border: "none" }}>
                                <h4 className='text-white fw-2 text-center w-100'>{requester?.displayName}</h4>
                            </Button>
                            <p className='text-white text-center w-75'>
                                {message}
                            </p>
                            <div className='d-flex align-items-center w-100 justify-content-evenly'>
                                <Button className="profile-btn" onClick={() => handleRequest('approve')}>
                                    Approve
                                    <img src={allow} height={18} className='ms-1' />
                                </Button>
                                <Button className="profile-btn" onClick={() => handleRequest('decline')}>
                                    Decline
                                    <img src={decline} height={25} />
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ReviewRequest;
