import React from 'react'
import '../styles/cards.css';
import { useNavigate } from 'react-router-dom';

function QRCodes({ id, status }) {
    const navigate = useNavigate();
    return (
        <div className='d-flex mb-2 departed-profile-border py-3 pe-2' style={{ cursor: "pointer" }} onClick={() => {
            navigate(`/QR/${id}`);
        }}>
            <div className='ms-2 d-flex justify-content-between w-100'>
                <p className='text-white mb-0'>{id}</p>
                {status ? <p className='text-white bg-danger px-1 rounded-1 small mb-0 '>Expired</p> : <p className='text-white bg-success small mb-0'>Available</p>}
            </div>

        </div>
    )
}

export default QRCodes