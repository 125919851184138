import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  padding: 0 20px;
  margin-block: 20px;
  width: 80%;
  justify-content: center;
`;

const DIVWrapper = styled.div`
  display: flex;
  overflow-x : auto;
  scrollbar-color: #000000 #000000;
`;

const HighLightWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 15px;
`;

const HighLightImage = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid #919191;
  padding: 4px;
  cursor: pointer;
  object-fit: cover;
`;

const HighLightLabel = styled.span`
  font-size: 12px;
  white-space: nowrap;
  margin-top: 6px;
  color: white;
  font-weight: 600;
`;

function Highlights() {
  const highlights = useSelector((state) => state.highlights);
  const navigate = useNavigate();
  const [videoThumbnails, setVideoThumbnails] = useState({});

  useEffect(() => {
    const generateVideoThumbnail = async () => {
      for (const highlight of highlights) {
        if (highlight.type === 'video') {
          try {
            const video = document.createElement('video');
            video.src = highlight.src;
            video.currentTime = 1;
            video.addEventListener('loadeddata', () => {
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
              const thumbnail = canvas.toDataURL();
              setVideoThumbnails((prevThumbnails) => ({
                ...prevThumbnails,
                [highlight.src]: thumbnail,
              }));
            });
          } catch (error) {
            console.error('Error generating video thumbnail:', error);
          }
        }
      }
    };

    generateVideoThumbnail();
  }, [highlights]);

  return (
    <Wrapper>
      <DIVWrapper>
        {highlights.map((highlight, index) => (
          <HighLightWrapper key={index}>
            {highlight.type === 'image' ? (
              <HighLightImage
                src={highlight.src}
                alt={`Highlight ${index}`}
                onClick={() => navigate(`/story?src=${encodeURIComponent(highlight.src)}`)}
              />
            ) : (
              <HighLightImage
                src={videoThumbnails[highlight.src] || '/placeholder.jpg'} // Placeholder image for videos
                alt={`Highlight ${index}`}
                onClick={() => navigate(`/story?src=${encodeURIComponent(highlight.src)}&type=video`)}
              />
            )}
            <HighLightLabel>{highlight.label}</HighLightLabel>
          </HighLightWrapper>
        ))}
      </DIVWrapper>
    </Wrapper>
  );
}

export default Highlights;
