import React, { useState } from "react";
import styled from "styled-components";
import About from "./About";
import UploadStory from "../UploadStory";
import { BASE_URL } from "../../services/BASE_URL";
import { useNavigate } from "react-router-dom";
import editIcon from "../../assets/icons/pen.png";
import createIcon from "../../assets/icons/create.png";
import viewIcon from "../../assets/icons/view.png";

const ProfileDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-block: 12px;
  width: 100%;
`;

const ProfileInfo = styled.div`
  padding-top: 60px;
  width: 100%;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 175px;
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
`;

const ProfileImage = styled.img`
  position: absolute;
  bottom: -70%;
  left: 16%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 130px;
  border: 1px solid #919191;
  border-radius: 50%;
  padding: 4px;
  object-fit: cover;
  @media (max-width: 600px) {
    left: 50%;
  }

`;

const ProfileNameButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 9px;
  justify-content: space-between;
  flex-wrap: wrap; 
`;

const ProfileName = styled.h4`
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 20px;
  color: white;
`;

const Button = styled.button`
  background-color: #A1281F;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;


  span {
    display: inline-block;
  }

  @media (max-width: 600px) {
    span {
      display: none;
    }
  }
`;

const ProfileCategory = styled.span`
  color: #919191;
  font-size: 14px;
`;

function ProfileDetails({ medallion, isUser }) {
  const [isModalOpen, setModalOpen] = useState(false);
  console.log(`${BASE_URL}${medallion.thumbNailPath}`, isUser);

  const navigate = useNavigate();

  return (
    <ProfileDetailsWrapper>
      <ImageWrapper>
        <ThumbnailImage src={`${BASE_URL}${medallion.thumbNailPath}`} alt="Thumbnail" />
        <ProfileImage src={`${BASE_URL}${medallion.imagePath}`} alt="Profile" />
      </ImageWrapper>
      <ProfileInfo>
        {isUser && <div className="d-flex mt-4 justify-content-end">
          <Button onClick={() => navigate(`/edit-deceased-profile/${medallion.id}`)}>
            <img src={editIcon} />
            <span className="ps-1">Edit Profile</span>
          </Button>
          <Button onClick={() => setModalOpen(true)}>
            <img src={createIcon} />
            <span className="ps-1">Create Story</span>
          </Button>
          <Button onClick={() => {
            navigate(`/QR/${medallion.qrCodeId}`)
          }}>
            <img src={viewIcon} />
            <span>View QR Code</span>
          </Button>
        </div>}
        <ProfileNameButton>
          <div>
            <ProfileName>{medallion.displayName}</ProfileName>
            <ProfileCategory>My Beloved {medallion.relationship}</ProfileCategory>
          </div>
        </ProfileNameButton>
        <About about={medallion.bioInformation} />
      </ProfileInfo>
      <UploadStory
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        medallionId={medallion.id}
      />
    </ProfileDetailsWrapper>
  );
}

export default ProfileDetails;
