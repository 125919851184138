import React from 'react';
import Stories from 'react-insta-stories';
import '../styles/story.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { height, width } from '../constants/Size';
import profile from '../assets/images/post2.jpg';
import likvideo from '../assets/videos/video1.mp4';

function ShowStory() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const type = params.get('type') || 'image';
  const src = params.get('src');

  const stories = [
    {
      url: src,
      duration: type === 'video' ? 30000 : 3000, 
      type: type,
      header: {
        heading: 'Nelson Mandela',
        subheading: 'Posted 30m ago',
        profileImage: profile,
      },
    },
    {
      url: likvideo,
      duration: 30000,
      type: 'video',
      header: {
        heading: 'Nelson Mandela',
        subheading: 'Posted 30m ago',
        profileImage: profile,
      },
    },
  ];

  const handleStoryEnd = () => {
    navigate(-1);
  };

  return (
    <div className='main_container'>
      <Stories
        stories={stories}
        defaultInterval={3000}
        width={width * 0.3}
        height={height}
        onAllStoriesEnd={handleStoryEnd}
      />
    </div>
  );
}

export default ShowStory;
