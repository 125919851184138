import React, { useState, useEffect } from 'react';
import '../styles/owner-profile.css';
import Header from '../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import AuthServices from '../services/AuthServices';
import logout from '../assets/icons/logout.png';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import profile from '../assets/images/person.jpeg';
import bell from '../assets/icons/bell.png';
import request from '../assets/icons/add-friend.png';
import QRCodeServices from '../services/QRCodeServices';

function OwnerProfileScreen() {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const getUser = await AuthServices.getUserDetails(navigate);
                setUser(getUser.data);
            } catch (error) {
                console.error("Error fetching user details:", error);
                toast.error("Failed to fetch user details.");
            } finally {
                setLoading(false);
            }
        };
        fetchUserDetails();
    }, [navigate]);

    const checkQRCode = async () => {
        setLoading(true);
        try {
            const qrcodes = await QRCodeServices.getQRCodes();
            const hasUnusedQRCode = qrcodes.data.some(qrcode => !qrcode.isUsed);
            if (hasUnusedQRCode) {
                navigate('/deceased-profile', { state: { qrcodes: qrcodes.data } });
            } else {
                navigate('/shop-now');
            }
        } catch (error) {
            console.error("Error checking QR code:", error);
            toast.error("Failed to check QR codes.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='main_container h-auto' style={{ minHeight: "100vh" }}>
            <div className='screen_container d-flex justify-content-between' style={{ height: loading ? "84.2vh" : "auto" }}>
                {loading ? (
                    <Loader />
                ) : (
                    <div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='w-50'>
                                <Header />
                            </div>
                            <div className='w-auto d-flex align-items-center'>
                                <Button className='profile-btn me-2' onClick={() => navigate('/notifications', { state: { user } })}>
                                    <img src={bell} alt='bell' height={23} />
                                </Button>
                                <Button className='profile-btn me-2' onClick={() => navigate('/requests', { state: { user } })}>
                                    <img src={request} alt='request' height={23} />
                                </Button>
                                <Button className='w-100 profile-btn' id="profile-btn" type="button" onClick={checkQRCode}>
                                    Add Profile
                                </Button>
                            </div>
                        </div>
                        <ToastContainer toastClassName='toaster' />
                        <div className='d-flex flex-column align-items-center mt-3'>
                            {user && (
                                <>
                                    <img src={user.imagePath || profile} alt='profile' className='profile-image mb-2' />
                                    <h4 className='text-white fw-2 text-center w-50'>Hi, {user.displayName}</h4>
                                    <p className='text-white text-center w-75'>
                                        Preserve cherished memories and share the stories of your ancestors or loved ones with others. Ensure their legacy lives on through meaningful tributes and remembrances.
                                    </p>
                                </>
                            )}
                        </div>
                        <div className='mx-auto mb-2' style={{ width: "65%" }}>
                            <Button className='profile-btn h-auto py-2 w-100' onClick={() => navigate(`/edit-profile/${user?.id}`)}>
                                <p className='text-white mb-0 text-center'>Edit Your Profile</p>
                            </Button>
                        </div>
                        <div className='mx-auto mb-2' style={{ width: "65%" }}>
                            <Button className='profile-btn h-auto py-2 w-100' onClick={() => navigate('/view-deceased-profile')}>
                                <p className='text-white mb-0 text-center'>View Departed Profiles</p>
                            </Button>
                        </div>
                        <div className='mx-auto mb-2' style={{ width: "65%" }}>
                            <Button className='profile-btn h-auto py-2 w-100' onClick={() => navigate('/view-QR-codes')}>
                                <p className='text-white mb-0 text-center'>View your QR codes</p>
                            </Button>
                        </div>
                        <div className='mx-auto mb-2' style={{ width: "65%" }}>
                            <Button className='profile-btn h-auto py-2 w-100' onClick={() => navigate('/shop-now')}>
                                <p className='text-white mb-0 text-center'>Buy QR Code</p>
                            </Button>
                        </div>
                        <div className='mx-auto mt-5' style={{ width: "30%" }}>
                            <Button className='profile-btn h-auto p-2 w-100' onClick={() => {
                                AuthServices.logout();
                                navigate('/login');
                            }}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={logout} alt='logout' height="15" />
                                    <p className='ms-2 text-white mb-0 text-center'>Logout</p>
                                </div>
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default OwnerProfileScreen;
