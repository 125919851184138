import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthServices from '../services/AuthServices';

const PrivateRoute = ({ element: Component, ...rest }) => {
    try {
        const user = AuthServices.getToken();
        console.log(user);
        return user ? <Component {...rest} /> : <Navigate to="/login" />;
    } catch (error) {
        console.error("Error fetching user token:", error);
    }
};

export default PrivateRoute;
