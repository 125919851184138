import React, { useEffect } from 'react';
import '../styles/splashscreen.css';
import { useNavigate } from 'react-router-dom';
// import Loader from '../components/Loader';
import logo from '../assets/images/logo.png';

function SplashScreen() {
    const navigate = useNavigate();
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/home');
        }, 3000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className='main_container' style={{ minHeight: "100vh" }}>
            <div className='circle'>
                <div className='loader'></div>
                <img src={logo} alt="Heaven's Code" className='splashLogo' />
            </div>
        </div>
    );
}

export default SplashScreen;
