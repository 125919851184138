import axios from 'axios';
import { BASE_URL } from './BASE_URL';
import AuthServices from './AuthServices';

const API_URL = `${BASE_URL}/api/FileUpload`;
const token = AuthServices.getToken();

const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(API_URL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token.data.access_token}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error uploading file:', error);
        return false;
    }
};

const UploadFileServices = {
    uploadFile,
};

export default UploadFileServices;
