import { toast } from "react-toastify";
import { auth, facebookProvider, googleProvider } from "../firebase/Firebase";
import { signInWithPopup } from "firebase/auth";
import AuthServices from "../services/AuthServices";
import { jwtDecode } from "jwt-decode";

export const decodeToken = (token) => {
    // console.log("token: ", token)
    try {
        const decoded = jwtDecode(token);
        return decoded;
    } catch (error) {
        console.error('Failed to decode token:', error);
        return null;
    }
};

export const FBlogin = async () => {
    try {
        const result = await signInWithPopup(auth, facebookProvider);
        console.log("Facebook User: ", result.user);
        console.log("Facebook Email: ", !result.user.email);

        if (!result.user.email) {
            toast.error("Something went wrong while authenticating with Facebook. Try other authentication methods");
            return false;
        } else {
            try {
                const response = await AuthServices.socialSignin(result.user.displayName, result.user.email, result.user.photoURL);
                if (response) {
                    return true;
                }
            } catch (error) {
                console.error("There was an error!", error);
                const errorMessage = error.response && error.response.data ? error.response.data.statusMessage : "Registration failed!";
                toast.error(errorMessage);
                return false;
            }
        }
    } catch (e) {
        console.log(`login error ${e}`);
        // toast.error("Something went wrong. Try again!");
        return false;
    }
};

export const googleLogin = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);

        const response = await AuthServices.socialSignin(result.user.displayName, result.user.email, result.user.photoURL);
        console.log(response);
        if (response) {
            return true;
        }
    } catch (error) {
        if (error.response && error.response.data) {
            const errorMessage = error.response.data.statusMessage;
            toast.error(errorMessage);
        } else {
            toast.error("Registration failed!");
        }
        console.error("There was an error!", error);
        // toast.error("Something went Wrong! Try Again..");
        return false;
    }
};

export function formatDateTime(isoDateStr) {
    const dateObj = new Date(isoDateStr);

    const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    };

    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const dateOptions = { month: 'long', day: 'numeric', year: 'numeric' };

    const time = dateObj.toLocaleTimeString('en-US', timeOptions);
    const date = dateObj.toLocaleDateString('en-US', dateOptions);

    return `${time}, ${date}`;
}

export function calculateAge(dateOfBirth, dateOfDeath) {
    const birthDate = new Date(dateOfBirth);
    const deathDate = new Date(dateOfDeath);

    let age = deathDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = deathDate.getMonth() - birthDate.getMonth();
    const dayDiff = deathDate.getDate() - birthDate.getDate();

    // Adjust age if the death date is before the birthday in the current year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    return age;
}


export function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    const [day, month, year] = formattedDate.split(' ');
    const dayWithSuffix = `${day}${getDaySuffix(day)}`;
    return `${dayWithSuffix} ${month}, ${year}`;
}

function getDaySuffix(day) {
    const dayNumber = parseInt(day, 10);
    if (dayNumber >= 11 && dayNumber <= 13) return 'th';
    switch (dayNumber % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}
